import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loding';

export default function Posiotion() {
	const history = useHistory();
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const pageTitle = 'Հաստիք';

	const [nameEn, setnameEn] = useState('');
	const [nameRu, setnameRu] = useState('');
	const [nameHy, setnameHy] = useState('');

	const modelSendToServer = {
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy,
		  },
	};

	//! components specific functions
	async function createItem() {
		setBusy(true);
		await API.post(config.api.positions, modelSendToServer);
		history.goBack();
	}
	async function updateItem() {
		setBusy(true);
		await API.update(config.api.positions, id, modelSendToServer);
		history.goBack();
	}
	async function removeItem() {
		setBusy(true);
		await API.remove(config.api.positions, id);
		history.goBack();
	}

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				let currentItem = await API.get(config.api.positions, {
					_id: id,
				});
				setnameEn(currentItem[0]?.name?.en);
				setnameRu(currentItem[0]?.name?.ru);
				setnameHy(currentItem[0]?.name?.hy);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={config.api.sliders}
				pageTitle={pageTitle}
				UPDATE={updateItem}
				CREATE={createItem}
				REMOVE={removeItem}
				inputs={[
					{
						value: nameHy,
						setter: setnameHy,
						type: 'text',
						usageType: 'textfield',
						label: 'Անունը հայերեն',
						placeholder: 'Մուտքագրել անունը հայատառ',
						lang: 'hy'
					},
					{
						value: nameRu,
						setter: setnameRu,
						type: 'text',
						usageType: 'textfield',
						label: 'Имя на русском',
						placeholder: 'Ввести имя на кирилице',
						lang: 'ru'
					},
					{
						value: nameEn,
						setter: setnameEn,
						type: 'text',
						usageType: 'textfield',
						label: 'Name in english',
						placeholder: 'Input name in latin',
						lang: 'en'
					},
				]}
			/>
		);
}
