import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useHistory } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import getUploads from "../../helpers/getUploads";

export default function ContactPage() {
  const history = useHistory();
  const [isBusy, setBusy] = useState(true);
  const pageTitle = "Նկարագրություն";
  const [descHy, setdescHy] = useState("");
  const [descEn, setdescEn] = useState("");
  const [descRu, setdescRu] = useState("");
  const [id, setid] = useState("");
  const [image, setImage] = useState(undefined);

  const modelSendToServer = {
    desc: {
      en: descEn,
      ru: descRu,
      hy: descHy,
    },
  };

  //! components specific functions
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.contactPage, id, modelSendToServer);
    history.goBack();
  }
  async function removeFile(id) {
    setBusy(true);
    setImage("");
    await API.remove(config.api.uploads, id);
  }
  async function uploadFile(files, lang, destiny) {
    if (id === "add") {
      const rowData = await API.post(config.api.portfolio, modelSendToServer);
      let id = rowData._id;
      await history.push(`${id}`);
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    } else {
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    }
  }

  useEffect(() => {
    (async () => {
      let currentItems = await API.get(config.api.contactPage);
      setid(currentItems[0]?._id);
      setdescEn(currentItems[0]?.desc?.en);
      setdescRu(currentItems[0]?.desc?.ru);
      setdescHy(currentItems[0]?.desc?.hy);

      let images = await getUploads(id, "image");
      setImage(images && images[0]);

      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [id, isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.history}
        pageTitle={pageTitle}
        UPDATE={updateItem}
        inputs={[
          {
            value: descHy,
            setter: setdescHy,
            type: "text",
            usageType: "textarea",
            label: "Հայերեն",
            placeholder: "Մուտքագրել հայատառ",
            lang: 'hy'
          },
          {
            value: descRu,
            setter: setdescRu,
            type: "text",
            usageType: "textarea",
            label: "На русском",
            placeholder: "Ввести на кирилице",
            lang: 'ru'
          },
          {
            value: descEn,
            setter: setdescEn,
            type: "text",
            usageType: "textarea",
            label: "In english",
            placeholder: "Input in latin",
            lang: 'en'
          },
        ]}
        filesComponent={[
          {
            destiny: "image",
            file: image,
            filesUploadLimit: 1,
            acceptedFiles: ["image/*"],
            uploadFile: uploadFile,
            removeFile: removeFile,
            buttonAddText: "Add",
            buttonOpenText: "Open",
            buttonRemoveText: "Remove",
            dropzoneText: "There is no file, click to upload a new one",
          },
        ]}
      />
    );
}
