import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loding';

export default function Slider() {
	const history = useHistory();
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const pageTitle = 'Սլայդեր';

	const [nameEn, setnameEn] = useState('');
	const [nameRu, setnameRu] = useState('');
	const [nameHy, setnameHy] = useState('');
	const [images, setImages] = useState(undefined);
	const [file, setfile] = useState(undefined);
	const modelSendToServer = {
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy,
			},
	};

	//! components specific functions
	async function createItem() {
		setBusy(true);
		await API.post(config.api.slider, modelSendToServer);
		let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
		history.push(previousUrl);
	}
	async function updateItem() {
		setBusy(true);
		await API.update(config.api.slider, id, modelSendToServer);
		let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
		history.push(previousUrl);
	}
	async function removeItem() {
		setBusy(true);
		await API.remove(config.api.slider, id);
		history.goBack();
	}

	async function removeFile(id) {
		setBusy(true);
		setfile("");
		await API.remove(config.api.uploads, id);
	  }
	  async function uploadFile(files, lang, destiny) {
		if (id === "add") {
		  const rowData = await API.post(config.api.slider, modelSendToServer);
		  let id = rowData._id;
		  await history.push(`${id}`);
		  let result = await API.upload(id, files, lang, destiny);
		  if (result) setBusy(true);
		} else {
		  let result = await API.upload(id, files, lang, destiny);
		  if (result) setBusy(true);
		}
	}

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				let currentItem = await API.get(config.api.slider, {
					_id: id,
				});
				setnameEn(currentItem[0]?.name?.en);
				setnameRu(currentItem[0]?.name?.ru);
				setnameHy(currentItem[0]?.name?.hy);

				let files;
				if (id) {
					files = await API.get(config.api.uploads, {
						obj_id: id,
						type: { $regex: '.*image.*' }
					});
					setfile(files);
        		}
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, history.location.pathname]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={config.api.sliders}
				pageTitle={pageTitle}
				UPDATE={updateItem}
				CREATE={createItem}
				REMOVE={removeItem}
				inputs={[
					{
						value: nameHy,
						setter: setnameHy,
						type: 'text',
						usageType: 'textfield',
						label: 'Անունը հայերեն',
						placeholder: 'Մուտքագրել անունը հայատառ',
						lang: 'hy'
					},
					{
						value: nameRu,
						setter: setnameRu,
						type: 'text',
						usageType: 'textfield',
						label: 'Имя на русском',
						placeholder: 'Ввести имя на кирилице',
						lang: 'ru'
					},
					{
						value: nameEn,
						setter: setnameEn,
						type: 'text',
						usageType: 'textfield',
						label: 'Name in english',
						placeholder: 'Input name in latin',
						lang: 'en'
					},
				]}
				sliderComponent={[
					{
						name: 'images',
						files: file,
						filesUploadLimit: 10,
						acceptedFiles: ['image/*'],
						buttonAddText: 'Ավելացնել ֆայլեր',
						buttonSaveText: 'Պահպանել',
						buttonCloseText: 'Փակել',
						buttonRemoveText: 'Ջնջել',
						uploadFile: uploadFile,
						removeFile: removeFile,
						dropzoneText:
							'Բացակայում են ֆայլերը, սեղմեք այստեղ՝ պատկերներ ավելացնելու համար:',
					},
				]}

			/>
		);
}
