import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import { useHistory } from "react-router-dom";
import Loading from '../modules/loding';

export default function Positions() {
    const [apiData, setApiData] = useState({});
    const [isBusy, setBusy] = useState(true);
    let history = useHistory();
    const pageTitle = 'Հաստիք'

    const addNew = ()=>{
        history.push(`add`)
    }

    useEffect(() => {
        (async () => {
            let rawData = await API.get(config.api.positions)
            setApiData(rawData)
            setBusy(false)
        })()
    }, [isBusy])

    const columns = [
        {
            dataField: 'name.en',
            text: 'Name',
            filter: true,
            sort: true
        },
        {
            dataField: 'name.ru',
            text: 'Имя',
            filter: true,
            sort: true
        },
        {
            dataField: 'name.hy',
            text: 'Անուն',
            filter: true,
            sort: true
        }
	];

    if (isBusy) return <Loading/>
    else return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12" align="center">
                        <h1>{`${pageTitle}ների ցուցակ`}</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <ListPage
                                        apiData={apiData}
                                        columns={columns}
                                        route={config.api.positions}
                                        addNew={addNew}
                                        pageTitle={pageTitle}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
