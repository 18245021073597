import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import { useHistory } from 'react-router-dom';
import Loading from '../modules/loding';

export default function News() {
  const history = useHistory();
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const pageTitle = 'Նորություն';

  const [titleEn, settitleEn] = useState('');
  const [titleRu, settitleRu] = useState('');
  const [titleHy, settitleHy] = useState('');
  const [descEn, setdescEn] = useState('');
  const [descRu, setdescRu] = useState('');
  const [descHy, setdescHy] = useState('');
  const [date, setdate] = useState('');
  const [file, setfile] = useState(undefined);
  // eslint-disable-next-line
  const [slider_id, setslider_id] = useState('');
  // eslint-disable-next-line
  const [sliders, setsliders] = useState('');
  const modelSendToServer = {
    title: {
      en: titleEn,
      ru: titleRu,
      hy: titleHy,
    },
    desc: {
      en: descEn,
      ru: descRu,
      hy: descHy,
    },
    slider_id: slider_id ? slider_id : undefined,
    date,
  };

  //! components specific functions
  async function createItem() {
    setBusy(true);
    await API.post(config.api.news, modelSendToServer);
    history.goBack();
  }
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.news, id, modelSendToServer);
    history.goBack();
  }
  async function removeItem() {
    setBusy(true);
    await API.remove(config.api.news, id);
    history.goBack();
  }

  async function removeFile(id) {
    setBusy(true);
    setfile('');
    await API.remove(config.api.uploads, id);
  }
  async function uploadFile(files, lang, destiny) {
    if (id === 'add') {
      const rowData = await API.post(config.api.news, modelSendToServer);
      let id = rowData._id;
      await history.push(`${id}`);
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    } else {
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    }
  }

  useEffect(() => {
    (async () => {
      let rawSliders = await API.get(config.api.slider);
      if (rawSliders) {
        setsliders(rawSliders);
      }
      let localValue = {};
      if (id !== 'add') {
        let currentItem = await API.get(config.api.news, {
          _id: id,
        });
        settitleEn(currentItem[0]?.title?.en);
        settitleRu(currentItem[0]?.title?.ru);
        settitleHy(currentItem[0]?.title?.hy);
        setdescEn(currentItem[0]?.desc?.en);
        setdescRu(currentItem[0]?.desc?.ru);
        setdescHy(currentItem[0]?.desc?.hy);

        let files;
        if (id) {
          files = await API.get(config.api.uploads, {
            obj_id: id,
            destiny: 'image',
          });
          setfile(files && files[0]);
        }

        rawSliders.forEach((item) => {
          if (item._id === currentItem[0]?.slider_id) {
            setslider_id(item);
          }
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.news}
        pageTitle={pageTitle}
        UPDATE={updateItem}
        CREATE={createItem}
        REMOVE={removeItem}
        inputs={[
          {
            value: titleHy,
            setter: settitleHy,
            type: 'text',
            usageType: 'textfield',
            label: 'Վերնագիրը հայերեն',
            placeholder: 'Մուտքագրել անունը հայատառ',
            lang: 'hy'
          },
          {
            value: titleRu,
            setter: settitleRu,
            type: 'text',
            usageType: 'textfield',
            label: 'Титл на русском',
            placeholder: 'Ввести имя на кирилице',
            lang: 'ru'
          },
          {
            value: titleEn,
            setter: settitleEn,
            type: 'text',
            usageType: 'textfield',
            label: 'Title in english',
            placeholder: 'Input name in latin',
            lang: 'en'
          },
          {
            value: date,
            setter: setdate,
            type: 'date',
            usageType: 'datePicker',
            label: 'Ամսաթիվ',
            placeholder: 'Ընտրել ամսաթիվը',
          },
          {
            value: descHy,
            setter: setdescHy,
            type: 'text',
            usageType: 'textarea',
            label: 'Նկարագրություն',
            placeholder: 'Մուտքագրել անունը հայատառ',
            lang: 'hy'
          },
          {
            value: descRu,
            setter: setdescRu,
            type: 'text',
            usageType: 'textarea',
            label: 'Описание',
            placeholder: 'Ввести имя на кирилице',
            lang: 'ru'
          },
          {
            value: descEn,
            setter: setdescEn,
            type: 'text',
            usageType: 'textarea',
            label: 'Description',
            placeholder: 'Input name in latin',
            lang: 'en'
          },
          {
            value: slider_id,
            setter: setslider_id,
            optionListValue: sliders,
            type: 'optionlist',
            usageType: 'optionlist',
            label: 'Սլայդեր',
          },
        ]}
        filesComponent={[
          {
            destiny: 'image',
            file: file,
            filesUploadLimit: 1,
            acceptedFiles: ['image/*'],
            uploadFile: uploadFile,
            removeFile: removeFile,
            buttonAddText: 'Ավելացնել',
            buttonOpenText: 'Բացել',
            buttonRemoveText: 'Ջնջել',
            dropzoneText:
              'Բացակայում է ֆայլը, սեղմեք այստեղ՝ պատկեր ավելացնելու համար:',
          },
        ]}
      />
    );
}
