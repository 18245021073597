import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loding';

export default function Job() {
  const history = useHistory();
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const pageTitle = 'Աշխատանք';

  const [nameEn, setnameEn] = useState('');
  const [nameRu, setnameRu] = useState('');
  const [nameHy, setnameHy] = useState('');
  const [descEn, setdescEn] = useState('');
  const [descRu, setdescRu] = useState('');
  const [descHy, setdescHy] = useState('');
  const [file, setfile] = useState(undefined);

  // eslint-disable-next-line
  const [project_id, setproject_id] = useState('');
  // eslint-disable-next-line
  const [projects, setprojects] = useState('');

  // eslint-disable-next-line
  const [slider_id, setslider_id] = useState('');
  // eslint-disable-next-line
  const [sliders, setsliders] = useState('');

  let modelSendToServer = {};

  if (project_id && slider_id) {
    modelSendToServer = {
      name: {
        en: nameEn,
        ru: nameRu,
        hy: nameHy,
      },
      desc: {
        en: descEn,
        ru: descRu,
        hy: descHy,
      },
      project_id,
      slider_id,
    };
  } else if (project_id) {
    modelSendToServer = {
      name: {
        en: nameEn,
        ru: nameRu,
        hy: nameHy,
      },
      desc: {
        en: descEn,
        ru: descRu,
        hy: descHy,
      },
      project_id,
    };
  } else if (slider_id) {
    modelSendToServer = {
      name: {
        en: nameEn,
        ru: nameRu,
        hy: nameHy,
      },
      desc: {
        en: descEn,
        ru: descRu,
        hy: descHy,
      },
      slider_id,
    };
  } else {
    modelSendToServer = {
      name: {
        en: nameEn,
        ru: nameRu,
        hy: nameHy,
      },
      desc: {
        en: descEn,
        ru: descRu,
        hy: descHy,
      },
    };
  }

  //! components specific functions
  async function createItem() {
    setBusy(true);
    await API.post(config.api.portfolio, modelSendToServer);
    history.goBack();
  }
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.portfolio, id, modelSendToServer);
    history.goBack();
  }
  async function removeItem() {
    setBusy(true);
    await API.remove(config.api.portfolio, id);
    history.goBack();
  }

  async function removeFile(id) {
    setBusy(true);
    setfile('');
    await API.remove(config.api.uploads, id);
  }
  async function uploadFile(files, lang, destiny) {
    if (id === 'add') {
      const rowData = await API.post(config.api.portfolio, modelSendToServer);
      let id = rowData._id;
      await history.push(`${id}`);
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    } else {
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    }
  }

  useEffect(() => {
    (async () => {
      let rawProjects = await API.get(config.api.projects);
      if (rawProjects) {
        setprojects(rawProjects);
      }
      let rawSliders = await API.get(config.api.slider);
      if (rawSliders) {
        setsliders(rawSliders);
      }

      if (id !== 'add') {
        let currentItem = await API.get(config.api.portfolio, {
          _id: id,
        });
        setdescEn(currentItem[0]?.desc?.en);
        setdescRu(currentItem[0]?.desc?.ru);
        setdescHy(currentItem[0]?.desc?.hy);
        setnameEn(currentItem[0]?.name?.en);
        setnameRu(currentItem[0]?.name?.ru);
        setnameHy(currentItem[0]?.name?.hy);
        setslider_id(currentItem[0]?.slider_id);
        let files;
        if (id) {
          files = await API.get(config.api.uploads, {
            obj_id: id,
            destiny: 'image',
          });
          setfile(files && files[0]);
        }

        rawProjects.forEach((item) => {
          if (item._id === currentItem[0]?.project_id) {
            setproject_id(item);
          }
        });
        rawSliders.forEach((item) => {
          if (item._id === currentItem[0]?.slider_id) {
            setslider_id(item);
            console.log(444);
          }
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.portfolio}
        pageTitle={pageTitle}
        UPDATE={updateItem}
        CREATE={createItem}
        REMOVE={removeItem}
        inputs={[
          {
            value: nameHy,
            setter: setnameHy,
            type: 'text',
            usageType: 'textfield',
            label: 'Անունը հայերեն',
            placeholder: 'Մուտքագրել անունը հայատառ',
            lang: 'hy'
          },
          {
            value: nameEn,
            setter: setnameEn,
            type: 'text',
            usageType: 'textfield',
            label: 'Name in english',
            placeholder: 'Input name in latin',
            lang: 'en'
          },
          {
            value: nameRu,
            setter: setnameRu,
            type: 'text',
            usageType: 'textfield',
            label: 'Имя на русском',
            placeholder: 'Ввести имя на кирилице',
            lang: 'ru'
          },
          {
            value: project_id,
            setter: setproject_id,
            optionListValue: projects,
            type: 'optionlist',
            usageType: 'optionlist',
            label: 'Նախագիծ',
          },
          {
            value: slider_id,
            setter: setslider_id,
            optionListValue: sliders,
            type: 'optionlist',
            usageType: 'optionlist',
            label: 'Սլայդեր',
          },
          {
            value: descHy,
            setter: setdescHy,
            type: 'text',
            usageType: 'textarea',
            label: 'Նկարագրություն',
            placeholder: 'Մուտքագրել անունը հայատառ',
            lang: 'hy'
          },
          {
            value: descRu,
            setter: setdescRu,
            type: 'text',
            usageType: 'textarea',
            label: 'Описание',
            placeholder: 'Ввести имя на кирилице',
            lang: 'ru'
          },
          {
            value: descEn,
            setter: setdescEn,
            type: 'text',
            usageType: 'textarea',
            label: 'Description',
            placeholder: 'Input name in latin',
            lang: 'en'
          },
        ]}
        filesComponent={[
          {
            destiny: 'image',
            file: file,
            filesUploadLimit: 1,
            acceptedFiles: ['image/*'],
            uploadFile: uploadFile,
            removeFile: removeFile,
            buttonAddText: 'Ավելացնել',
            buttonOpenText: 'Բացել',
            buttonRemoveText: 'Ջնջել',
            dropzoneText:
              'Բացակայում է ֆայլը, սեղմեք այստեղ՝ պատկեր ավելացնելու համար:',
          },
        ]}
      />
    );
}
