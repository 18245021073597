const config = {
  api: {
    clients: "clients",
    partners: "partners",
    contactus: "contact-us",
    news: "news",
    joinus: "join-us",
    services: "services",
    portfolio: "portfolio",
    animatedNumbers: "animated-numbers",
    projects: "projects",
    wchus: "wchus",
    opinion: "opinion",
    contactPart: "contact-part",
    contactPage: "contact-page",
    contactType: "contact-type",
    aboutUs: {
      mission: "aboutus-mission",
      history: "aboutus-history",
      team: "aboutus-team",
      license: "aboutus-license",
    },
    slider: "slider",
    positions: "position",

    uploads: "uploads",
    API_URL: process.env.REACT_APP_API_URL,
    API_Version: "v2",
  },
  authentification: {
    login: "/login/private",
    verify: "/verify",
    tokenAddress: "private-token",
  },
};

export default config;
