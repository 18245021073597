import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as API from '../helpers/api';
import config from '../config';

export function ListPage(props) {
    let history = useHistory();
    const [isBusy, setBusy] = useState(true);
    const [columns, setColumns] = useState(props.columns);
    const [images, setImages] = useState('');
    const [icons, setIcons] = useState('');
    const [pdfs, setpdfs] = useState('');
    const [positions, setpositions] = useState('');
    const [projects, setprojects] = useState('');
    let editedPageTitle = `${props.pageTitle}`;
    const defaultSorted = [
        {
            dataField: 'name.en',
            order: 'asc',
        },
    ];

    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        disablePageTitle: true,
        sizePerPageList: [
            {
                text: '5',
                value: 5,
            },
            {
                text: '10',
                value: 10,
            },
            {
                text: 'All',
                value: props.apiData.length,
            },
        ], // A numeric array is also available. the purpose of above example is custom the text
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            history.push(`/${props.route}/${row._id}`);
        },
    };

    const imageFormatter = (cell, row, rowIndex, formatExtraData) => {
        let result = <i className='fas fa-minus'></i>;
        images.forEach((image) => {
            if (image.obj_id === row._id)
                result = <i className='far fa-image'></i>;
        });
        return result;
    };
	
    const iconFormatter = (cell, row, rowIndex, formatExtraData) => {
        let result = <i className='fas fa-minus'></i>;
        icons.forEach((icon) => {
            if (icon.obj_id === row._id)
                result = <i className='far fa-image'></i>;
        });
        return result;
    };

    const pdfFormatter = (cell, row, rowIndex, formatExtraData) => {
        let result = <i className='fas fa-minus'></i>;
        pdfs.forEach((pdf) => {
            if (pdf.obj_id === row._id)
                result = <i className='far fa-image'></i>;
        });
        return result;
    };
    const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        let result = new Date(cell).toLocaleDateString();
        // let result = moment(cell).format("DD-MM-YY");
        return result;
    };

	const positionFormatter = (cell, row, rowIndex, formatExtraData) => {
		let result = '';
		positions.forEach((item) => {
			if (item._id === row.position_id) {
				result = item.name.hy;
			}
		});
		return result;
	};
	const projectFormatter = (cell, row, rowIndex, formatExtraData) => {
		let result = '';
		projects.forEach((item) => {
			if (item._id === row.project_id) {
				result = item.nameHy;
			}
		});
		return result;
	};

    columns.forEach((column) => {
        if (column.filter === true) {
            column.filter = textFilter();
        }
        if (column.formatImage === true) {
            column.formatter = imageFormatter;
        }
        if (column.formatIcon === true) {
            column.formatter = iconFormatter;
        }
        if (column.formatPdf === true) {
            column.formatter = pdfFormatter;
        }
        if (column.formatDate === true) {
            column.formatter = dateFormatter;
        }
        if (column.formatPosition === true) {
            column.formatter = positionFormatter;
        }
        if (column.formatProject === true) {
            column.formatter = projectFormatter;
        }
    });

    useEffect(() => {
        (async () => {
            let images = await API.get(config.api.uploads, {
                destiny: 'image',
            });
            setImages(images);

            let icons = await API.get(config.api.uploads, {
                destiny: 'icon',
            });
            setIcons(icons);

            let pdfs = await API.get(config.api.uploads, {
                type: { $regex: '.*pdf.*' },
            });
            setpdfs(pdfs);

            let Positions = await API.get(config.api.positions);
            setpositions(Positions);

            let Projects = await API.get(config.api.projects);
            setprojects(Projects);

            setColumns(props.columns);
            setBusy(false);
        })();
        // eslint-disable-next-line
    }, [isBusy]);
    if (isBusy) return <h1>loading</h1>;
    else
        return (
            <>
                <button className='btn btn-primary mb-3' onClick={props.addNew}>
                    <i className='fas fa-plus-square'></i>
                    <span
                        style={{
                            marginLeft: 10,
                        }}>{`Add ${editedPageTitle}`}</span>
                </button>
                <BootstrapTable
                    bootstrap4
                    hover
                    keyField='_id'
                    data={props.apiData}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    filter={filterFactory()}
                    pagination={paginationFactory(options)}
                    rowEvents={rowEvents}
                />
            </>
        );
}
