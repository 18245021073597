export const navigationPrimary = [
  {
    name: "մեր մասին",
    route: "about-us",
    icon: "fas fa-address-card",
    collapse: [
      {
        name: "առաքելությունը",
        route: "aboutus-mission",
        icon: "fas fa-route",
      },
      {
        name: "պատմությունը",
        route: "aboutus-history",
        icon: "fas fa-history",
      },
      { name: "թիմը", route: "aboutus-team", icon: "fas fa-users" },
      {
        name: "լիցենզիաներ",
        route: "aboutus-license",
        icon: "fas fa-file",
      },
    ],
  },
  {
    name: "Հիմնական էջի թվերը",
    route: "animated-numbers",
    icon: "fas fa-briefcase",
  },
  { name: "ինչու ընտրել մեզ", route: "wchus", icon: "fas fa-briefcase" },
  { name: "աշխատանքներ", route: "portfolio", icon: "fas fa-briefcase" },
  { name: "նախագծեր", route: "projects", icon: "fas fa-briefcase" },
  {
    name: "ծառայություններ",
    route: "services",
    icon: "fas fa-concierge-bell",
  },
  { name: "նորություններ", route: "news", icon: "fas fa-newspaper" },
  {
    name: "միացեք մեզ",
    route: "join-us",
    icon: "fas fa-users",
  },
  { name: "հաճախորդներ", route: "clients", icon: "fas fa-user-tie" },
  { name: "գործընկերներ", route: "partners", icon: "fas fa-users" },
  { name: "Կարծիքներ", route: "opinion", icon: "fas fa-users" },
  {
    name: "Կոնտակտներ",
    icon: "fas fa-folder",
    collapse: [
      {
        name: "Նկարագրություն",
        route: "contact-page",
        icon: "fas fa-address-book",
      },
      {
        name: "Էլեմենտներ",
        route: "contact-part",
        icon: "fas fa-address-book",
      },
      {
        name: "Տիպեր",
        route: "contact-type",
        icon: "far fa-address-book",
      },
    ],
  },
];
export const navigationSecondary = [
  { name: "սլայդեր", route: "slider", icon: "fas fa-clone" },
  { name: "հաստիքներ", route: "position", icon: "fas fa-people-arrows" },
  { name: "մեդիա", route: "media", icon: "fas fa-icons" },
];
