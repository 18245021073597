import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ChromePicker } from "react-color";
import Swal from "sweetalert2";
import { isIterable } from "../../helpers/global";
import config from "../../config";

let timeout = null;
const Input = (props) => {
  const [showColorPicker, setshowColorPicker] = useState(false);
  const [bloko, setbloko] = useState("");
  const regex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let typingTimer;
  let doneTypingInterval = 3000;

  const handleChange = async (e) => {
    let value = e.target?.value;
    if (props.trim) value = value.trim();
    if (props.tolowercase) value = value.toLowerCase();
    value = value.trimStart();

    if (props.servercall && props.api && props.apiurl) {
      // Init a timeout variable to be used below
      clearTimeout(timeout);
      timeout = setTimeout(async function () {
        if (props.type === "email") {
          let res = await props.api.get(props.apiurl, {
            email: value,
          });
          if (res[0]) {
            Swal.fire(props.config.swal.emailIsBusy[props.language]);
            props.setter("");
            return;
          }
        } else {
          let res = await props.api.get(props.apiurl, {
            username: value,
          });
          if (res[0]) {
            Swal.fire(props.config.swal.usernameIsBusy[props.language]);
            props.setter("");
            return;
          }
        }
      }, 1000);
    }
    props.setter(value);
  };

  const checkEmailInput = (e) => {
    if (props.value !== "" && props.value.match(regex)) {
    } else {
      props.setter("");
      Swal.fire(config.swal.emailNotValid);
    }
  };

  const checkPhoneInput = (e) => {
    if (props.value !== "" && props.value.match(regex)) {
    } else {
      props.setter("");
      Swal.fire(config.swal.emailNotValid);
    }
  };

  const handleEmailKeyUp = (e) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(checkEmailInput, doneTypingInterval);
  };
  const handleEmailKeyDown = (e) => {
    clearTimeout(typingTimer);
  };

  const handleEmailChange = (e) => {
    props.setter(e?.target?.value);
  };

  const handlePriceChange = (e) => {
    props.pricesetter(e?.target?.value);
  };

  const handleTimeIntervalChange = (e) => {
    props.settimeInterval(e?.target?.value);
  };

  const handleTextFieldChange = (data) => {
    props.setter(data);
  };

  const handleColorChange = (color) => {
    props.setter(color.hex);
  };

  const toggleShowColorPicker = () => {
    setshowColorPicker(!showColorPicker);
  };

  const handleCheckboxChange = () => {
    props.setter(!props.value);
  };

  if (props.usageType === "textfield") {
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        {props.disabled ? (
          <p>{props.value}</p>
        ) : (
          <input
            value={props.value || ""}
            onChange={handleChange}
            type={props.type}
            className="form-control"
            id={props.label}
            placeholder={props.placeholder}
          />
        )}
      </div>
    );
  } else if (props.usageType === "email") {
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        {props.disabled ? (
          <p>{props.value}</p>
        ) : (
          <input
            value={props.value || ""}
            onChange={handleEmailChange}
            onKeyUp={handleEmailKeyUp}
            onKeyDown={handleEmailKeyDown}
            type={props.type}
            className="form-control"
            id={props.label}
            placeholder={props.placeholder}
          />
        )}
      </div>
    );
  } else if (props.usageType === "textarea") {
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        <Editor
          id={(Math.random() * 100).toString()}
          init={{
            height: "50vh",
            width: "100%",
            strict_loading_mode: true,
            statusbar: false,
            placeholder: props.placeholder,
            fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
            menubar: "file edit insert view format table",
            plugins: [
              "charmap advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount code",
            ],
            formatpainter_blacklisted_formats:
              "link,address,removeformat,formatpainter_removeformat",
            toolbar:
              // eslint-disable-next-line
              "charmap | undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
          }}
          value={props.value}
          onEditorChange={handleTextFieldChange}
        />
      </div>
    );
  } else if (props.usageType === "optionlist") {
    if (props.optionListValue && Array.isArray(props.optionListValue)) {
      return (
        <div key={props.id} className="form-group form-element">
          <label htmlFor={props.label}>{props.label}</label>
          <select onChange={handleChange} className="form-control">
            {React.Children.toArray(
              props?.optionListValue?.map((opt) => {
                if (props.value) {
                  if (opt._id === props.value) {
                    return (
                      <option selected value={opt._id}>
                        {opt[props.field]
                          ? opt[props.field]
                          : opt.name.hy
                          ? opt.name.hy
                          : opt.name.ru
                          ? opt.name.ru
                          : opt.name.en
                          ? opt.name.en
                          : "none"}
                      </option>
                    );
                  } else {
                    return (
                      <>
                        <option value={opt._id}>
                          {opt[props.field]
                            ? opt[props.field]
                            : opt.name.hy
                            ? opt.name.hy
                            : opt.name.ru
                            ? opt.name.ru
                            : opt.name.en
                            ? opt.name.en
                            : "none"}
                        </option>
                      </>
                    );
                  }
                } else {
                  return (
                    <>
                      <option value="none" selected disabled hidden>
                        Select something
                      </option>
                      <option value={opt._id}>
                        {opt[props.field]
                          ? opt[props.field]
                          : opt.name?.en
                          ? opt.name?.en
                          : opt.name?.ru
                          ? opt.name?.ru
                          : opt.name?.hy
                          ? opt.name?.hy
                          : "none"}
                      </option>
                    </>
                  );
                }
              })
            )}
          </select>
        </div>
      );
    }
    return null;
  } else if (props.usageType === "optionlist-simple") {
    return (
      <div key={props.id} className="form-group form-element">
        <label htmlFor={props.label}>{props.label}</label>
        <select onChange={handleChange} className="form-control">
          {React.Children.toArray(
            props?.optionListValue?.map((opt) => {
              if (props.value) {
                if (opt.name[props.lang] === props.value) {
                  return (
                    <option selected value={opt.name[props.lang]}>
                      {opt.name.en
                        ? opt.name.en
                        : opt.name.ru
                        ? opt.name.ru
                        : opt.name.hy
                        ? opt.name.hy
                        : "none"}
                    </option>
                  );
                } else {
                  return (
                    <>
                      <option value={opt.name[props.lang]}>
                        {opt.name.en
                          ? opt.name.en
                          : opt.name.ru
                          ? opt.name.ru
                          : opt.name.hy
                          ? opt.name.hy
                          : "none"}
                      </option>
                    </>
                  );
                }
              } else {
                return (
                  <>
                    <option value="none" selected disabled hidden>
                      Select something
                    </option>
                    <option value={opt.name[props.lang]}>
                      {opt.name.en
                        ? opt.name.en
                        : opt.name.ru
                        ? opt.name.ru
                        : opt.name.hy
                        ? opt.name.hy
                        : "none"}
                    </option>
                  </>
                );
              }
            })
          )}
        </select>
      </div>
    );
  } else if (props.usageType === "datePicker") {
    let value = JSON.parse(JSON.stringify(props.value));
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        {props.disabled ? (
          <p>{moment(value).format("DD-MM-YY | h:mm:ss")}</p>
        ) : (
          <input
            value={value?.slice(0, 10) || ""}
            onChange={handleChange}
            type={props.type}
            className="Input-text form-control"
            id={props.label}
            required={props.required}
            // placeholder="YYYY"
            pattern={props.pattern}
          />
        )}
      </div>
    );
  } else if (props.usageType === "number") {
    return (
      <div key={props?.id} className="form-element">
        <label htmlFor={props?.label}>{props?.label}</label>
        <input
          value={props?.value || ""}
          onChange={handleChange}
          type={props?.type}
          className="form-control"
          id={props?.label}
          placeholder={props?.placeholder}
        />
      </div>
    );
  } else if (props.usageType === "email") {
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        <input
          value={props.value || ""}
          onChange={handleChange}
          type={props.usageType}
          className="form-control"
          id={props.label}
          placeholder={props.placeholder}
        />
      </div>
    );
  } else if (props.usageType === "boolean") {
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        {props.value ? (
          <p>
            <i className="fas fa-thumbs-up"></i>
          </p>
        ) : (
          <p>
            <i className="fas fa-exclamation-circle"></i>
          </p>
        )}
      </div>
    );
  } else if (props.usageType === "color-picker") {
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        <div className="input-group">
          <div className="input-group-btn">
            <button
              className="btn btn-info btn-flat"
              onClick={toggleShowColorPicker}
            >
              Color Picker
            </button>
          </div>
          {/* <p >color test</p> */}
          <input
            value={props.value || ""}
            onChange={handleChange}
            type={props.type}
            className="form-control"
            id={props.label}
            placeholder={props.placeholder}
          />
          <div className="input-group-btn">
            <button
              className="btn btn-flat"
              style={{ backgroundColor: props.value }}
            >
              Color Example
            </button>
          </div>
        </div>
        {showColorPicker && <ChromePicker onChange={handleColorChange} />}
      </div>
    );
  } else if (props.usageType === "currency") {
    let placeholder = {
      value: {
        en: "value",
        ru: "цена",
        hy: "արժեք",
      },
      duration: {
        en: "duration",
        ru: "продолжительность",
        hy: "տևողություն",
      },
    };
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        <div className="input-group">
          <div className="input-group-btn">
            {/* <button
              className="btn btn-info btn-flat"
              onClick={toggleShowColorPicker}
            >
              Color Picker
            </button> */}
            <select onChange={handleChange} className="btn btn-info btn-flat">
              {React.Children.toArray(
                props.optionListValue.map((opt) => {
                  if (props.value) {
                    if (opt._id === props.value) {
                      return (
                        <option selected value={opt._id}>
                          {opt.code}
                        </option>
                      );
                    } else {
                      return (
                        <>
                          <option value={opt._id}>{opt.code}</option>
                        </>
                      );
                    }
                  } else {
                    return (
                      <>
                        <option value="none" selected disabled hidden>
                          Select something
                        </option>
                        <option value={opt._id}>
                          {opt[props.field]
                            ? opt[props.field]
                            : opt.code
                            ? opt.code
                            : "none"}
                        </option>
                      </>
                    );
                  }
                })
              )}
            </select>
          </div>
          <input
            value={props.price || ""}
            onChange={handlePriceChange}
            type="number"
            className="form-control"
            id={props.label}
            placeholder={placeholder.value[props.lang]}
          />
          <input
            value={props.timeInterval || ""}
            onChange={handleTimeIntervalChange}
            type="number"
            className="form-control"
            id={props.label}
            placeholder={placeholder.duration[props.lang]}
          />
        </div>
        {showColorPicker && <ChromePicker onChange={handleColorChange} />}
      </div>
    );
  } else if (props.usageType === "timepast") {
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        <div className="input-group">
          <p>
            {moment
              .utc(
                moment(moment(), "DD/MM/YYYY HH:mm:ss").diff(
                  moment(props.value, "DD/MM/YYYY HH:mm:ss")
                )
              )
              .format("HH:mm")}
          </p>
        </div>
      </div>
    );
  } else if (props.usageType === "timeleft") {
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        <div className="input-group">
          <p>{moment(new Date(props.value) - new Date()).format("DD:HH:mm")}</p>
        </div>
      </div>
    );
  } else if (props.usageType === "bloko") {
    return (
      <div key={props.id} className={`Input ${props.classNames}`}>
        <ul className="knowledges">
          {React.Children.toArray(
            isIterable(props?.value) &&
              props?.value?.map((skill, i) => (
                <li>
                  <div className="bloko-container">
                    <div className="bloko-name">{skill}</div>
                    <img
                      key={i}
                      onClick={() => {
                        let cloneArr = [...props.value];
                        cloneArr.splice(i, 1);
                        props.setter(cloneArr);
                      }}
                      className="bloko-remove-icon"
                      src="/assets/delete.svg"
                      alt="skills delete icon"
                    />
                  </div>
                </li>
              ))
          )}
        </ul>
        <input
          value={bloko || ""}
          onChange={(e) => {
            setbloko(e.target.value);
          }}
          type={props.type}
          className="Input-text"
          id={props.label}
          placeholder={props.placeholder}
          pattern={props.pattern}
        />
        <span
          className={`Input-check ${bloko ? "" : "passive"}`}
          onClick={() => {
            let cloneArr = [...props.value];
            cloneArr.push(bloko);
            setbloko("");
            props.setter(cloneArr);
          }}
        >
          <img
            className="Input-check_img"
            src="/assets/check-solid.svg"
            alt="check svg"
          />
        </span>
      </div>
    );
  } else if (props.usageType === "checkbox") {
    return (
      <div key={props.id} className="form-element">
        <label htmlFor={props.label}>{props.label}</label>
        {props.disabled ? (
          <p>{props.value}</p>
        ) : (
          <input
            checked={props.value}
            value={props.value}
            onChange={handleCheckboxChange}
            type={props.type}
            className="form-control"
            id={props.label}
            placeholder={props.placeholder}
          />
        )}
      </div>
    );
  } else {
    return <h1 key={props.id}>There is no content to show in input</h1>;
  }
};

export default Input;
