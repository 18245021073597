import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
//! import modules

import Input from "./modules/input";
import { FilesComponent } from "./modules/filesComponent";
import { SliderComponent } from "./modules/sliderComponent";
import {useLang} from '../helpers/language';

// !Date stuff
export default function SinglePage(props) {
  const {
    inputs,
    simplePage,
    filesComponent,
    tableComponent,
    sliderComponent,
    UPDATE,
    REMOVE,
    CREATE,
    downlaodButton,
    downloadFunction,
    disableRemove,
    publishButton,
    paidButton,
    publishOrder,
    pay,
    pageTitle,
  } = props;

  let history = useHistory();
  let editMode;
  let editedPageTitle = "";
  const {lang, setLang} = useLang() || {};
  
  if (useParams().id === "add") {
    editMode = false;
    editedPageTitle = `Add ${pageTitle}`;
  } else {
    editMode = true;
    editedPageTitle = `Edit ${pageTitle}`;
  }

  // ! MAIN BUTTONS -------SAVE------REMOOVE------CANCEL
  const saveItem = () => {
    if (editMode) UPDATE();
    else CREATE();
  };
  const removeItem = () => {
    REMOVE();
  };
  const cancelBack = () => {
    history.goBack();
  };

  const [activator1, setactivator1] = useState(false);
  const [activator2, setactivator2] = useState(false);
  const [activator3, setactivator3] = useState(true);
  const tabs = [
    {name: 'Հայ', activator: activator3, setter: setactivator3, lang: 'hy'},
    {name: 'Рус', activator: activator2, setter: setactivator2, lang: 'ru'},
    {name: 'Eng', activator: activator1, setter: setactivator1, lang: 'en'},
  ];

  function toggleTab(e) {
    tabs.forEach((tab) => {
      tab.setter(false);
      if (e?.target?.id === tab?.lang) {
        tab.setter(true);
        setLang(tab.lang);
      }
    });
  }

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" align="center">
              <h1>{editedPageTitle}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header card-header d-flex p-0">
                  <h3 className="card-title p-2">Main Information</h3>
                  <ul className='nav nav-pills ml-auto'>
                      {tabs.map((tab, index) => (
                          <li className='nav-item' key={index}>
                            <button
                                onClick={toggleTab}
                                id={tab.lang}
                                className={`nav-link ${
                                    tab.activator ? 'active' : ''
                                }`}
                            >
                              {tab.name}
                            </button>
                          </li>
                      ))}
                    </ul>
                </div>
                <div role="form">
                  <div className="card-body">
                  {inputs
                          ? inputs.map((input, index) => {
                            if (input) {
                              if (input.lang) {
                                if (input.lang === lang) {
                                  return (
                                    <Input
                                      key={index}
                                      id={index}
                                      label={input.label}
                                      field={input.field}
                                      placeholder={input.placeholder}
                                      price={input.price}
                                      pricesetter={input.pricesetter}
                                      timeInterval={input.timeInterval}
                                      settimeInterval={input.settimeInterval}
                                      value={input.value}
                                      fullWidth={input.fullWidth}
                                      setter={input.setter}
                                      type={input.type}
                                      lang={input.lang}
                                      usageType={input.usageType}
                                      optionListValue={input.optionListValue}
                                      radioValue={input.radioValue}
                                      disabled={input.disabled}
                                      selectSomething={input.selectSomething}
                                    />
                                  );
                                }
                              } else {
                                return (
                                  <Input
                                  key={index}
                                  id={index}
                                  label={input.label}
                                  field={input.field}
                                  placeholder={input.placeholder}
                                  price={input.price}
                                  pricesetter={input.pricesetter}
                                  timeInterval={input.timeInterval}
                                  settimeInterval={input.settimeInterval}
                                  value={input.value}
                                  setter={input.setter}
                                  type={input.type}
                                  radioValue={input.radioValue}
                                  fullWidth={input.fullWidth}
                                  usageType={input.usageType}
                                  optionListValue={input.optionListValue}
                                  disabled={input.disabled}
                              />
                                );
                              }
                            }
                            return null;
                          })
                          : null}
                  </div>
                  <div className="card-footer">
                    <div className="btn-group">
                      <button className="btn btn-primary" onClick={saveItem}>
                        <i className="fas fa-save"></i>
                        <span style={{ marginLeft: 10 }}>Save</span>
                      </button>
                      <button className="btn btn-warning" onClick={cancelBack}>
                        <i className="fas fa-window-close"></i>
                        <span style={{ marginLeft: 10 }}>Cancel</span>
                      </button>
                      {publishButton && (
                        <button
                          className="btn btn-primary"
                          onClick={publishOrder}
                        >
                          <i className="fas fa-upload"></i>
                          <span style={{ marginLeft: 10 }}>Publish</span>
                        </button>
                      )}
                      {paidButton && (
                        <button className="btn btn-primary" onClick={pay}>
                          <i className="fas fa-upload"></i>
                          <span style={{ marginLeft: 10 }}>Payed !</span>
                        </button>
                      )}
                      {editMode ? (
                        simplePage ? null : (
                          <button
                            className="btn btn-danger"
                            onClick={removeItem}
                          >
                            <i className="fas fa-trash"></i>
                            <span
                              style={{
                                marginLeft: 10,
                              }}
                            >
                              Remove
                            </span>
                          </button>
                        )
                      ) : null}
                    </div>
                    {downlaodButton && (
                      <div className="btn-group" style={{ marginLeft: 10 }}>
                        <button
                          className="btn btn-primary"
                          onClick={downloadFunction}
                        >
                          <i className="fas fa-save"></i>
                          <span style={{ marginLeft: 10 }}>Download</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {filesComponent &&
              filesComponent.map((component, index) => {
                return (
                  <FilesComponent
                    key={index}
                    id={index}
                    destiny={component.destiny}
                    file={component.file}
                    filesUploadLimit={component.filesUploadLimit}
                    acceptedFiles={component.acceptedFiles}
                    uploadFile={component.uploadFile}
                    removeFile={component.removeFile}
                    lang={component.lang}
                    buttonAddText={component.buttonAddText}
                    buttonOpenText={component.buttonOpenText}
                    buttonRemoveText={component.buttonRemoveText}
                    dropzoneText={component.dropzoneText}
                  />
                );
              })}
            {sliderComponent &&
              sliderComponent.map((component, index) => {
                return (
                  <SliderComponent
                    key={index}
                    files={component.files}
                    name={component.name}
                    filesUploadLimit={component.filesUploadLimit}
                    acceptedFiles={component.acceptedFiles}
                    buttonAddText={component.buttonAddText}
                    buttonSaveText={component.buttonSaveText}
                    buttonCloseText={component.buttonCloseText}
                    buttonRemoveText={component.buttonRemoveText}
                    uploadFile={component.uploadFile}
                    removeFile={component.removeFile}
                    dropzoneText={component.dropzoneText}
                    lang={component.lang}
                  />
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
}
