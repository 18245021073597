import React from 'react';

export default function Home() {
  // const { setAuthTokens } = useAuth();
  // function logOut() {
  //   setAuthTokens();
  // }
  
  return (
    <div> 
      <div>Home</div>
    </div>
  );
}