import React, { useState, useEffect } from 'react';
import SinglePage from '../../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../../helpers/api';
import config from '../../../config';
import Loading from '../../modules/loding';

export default function Team() {
	const history = useHistory();
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const pageTitle = 'Աշխատակից';

	const [index, setindex] = useState("");
	const [nameEn, setnameEn] = useState('');
	const [nameRu, setnameRu] = useState('');
	const [nameHy, setnameHy] = useState('');
	const [position_id, setposition_id] = useState('');
	// eslint-disable-next-line
	const [position, setposition] = useState('');
	const [positions, setpositions] = useState('');
	const [file, setfile] = useState(undefined);
	
	let modelSendToServer = {};

	if (position_id) {
		modelSendToServer = {
			index,
			name: {
				en: nameEn,
				ru: nameRu,
				hy: nameHy,
			  },
			position_id,
		};
	} else {
		modelSendToServer = {
			index,
			name: {
				en: nameEn,
				ru: nameRu,
				hy: nameHy,
			  },
		};
	}

	//! components specific functions
	async function createItem() {
		setBusy(true);
		await API.post(config.api.aboutUs.team, modelSendToServer);
		history.goBack();
	}
	async function updateItem() {
		setBusy(true);
		await API.update(config.api.aboutUs.team, id, modelSendToServer);
		history.goBack();
	}
	async function removeItem() {
		setBusy(true);
		await API.remove(config.api.aboutUs.team, id);
		history.goBack();
	}
	async function removeFile(id) {
		setBusy(true);
		setfile("");
		await API.remove(config.api.uploads, id);
	  }
	  async function uploadFile(files, lang, destiny) {
		if (id === "add") {
		  const rowData = await API.post(config.api.aboutUs.team, modelSendToServer);
		  let id = rowData._id;
		  await history.push(`${id}`);
		  let result = await API.upload(id, files, lang, destiny);
		  if (result) setBusy(true);
		} else {
		  let result = await API.upload(id, files, lang, destiny);
		  if (result) setBusy(true);
		}
	}
	
	useEffect(() => {
		(async () => {
			let rawPositions = await API.get(config.api.positions);
			if(rawPositions){
				const positions = rawPositions;
				setpositions(positions);
			}
			if (id !== 'add') {
				let currentItem = await API.get(config.api.aboutUs.team, {
					_id: id,
				});
				setindex(currentItem[0]?.index);
				setnameEn(currentItem[0]?.name?.en);
				setnameRu(currentItem[0]?.name?.ru);
				setnameHy(currentItem[0]?.name?.hy);

				let files;
				if (id) {
					files = await API.get(config.api.uploads, {
						obj_id: id,
						destiny: "image",
					});
					setfile(files && files[0]);
        		}	
				rawPositions.forEach((pos) => {
					if (pos._id === currentItem[0]?.position_id) {
						setposition_id(pos._id);
					}
				});
				// setImage(images.data[0]);
			}

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={config.api.aboutUs.team}
				pageTitle={pageTitle}
				UPDATE={updateItem}
				CREATE={createItem}
				REMOVE={removeItem}
				inputs={[
					{
						value: index,
						setter: setindex,
						type: "number",
						usageType: "number",
						label: "Index",
						placeholder: "Index",
					  },
					{
						value: nameHy,
						setter: setnameHy,
						type: 'text',
						usageType: 'textfield',
						label: 'Անունը հայերեն',
						placeholder: 'Մուտքագրել անունը հայատառ',
						lang: 'hy'
					},
					{
						value: nameEn,
						setter: setnameEn,
						type: 'text',
						usageType: 'textfield',
						label: 'Name in english',
						placeholder: 'Input name in latin',
						lang: 'en'
					},
					{
						value: nameRu,
						setter: setnameRu,
						type: 'text',
						usageType: 'textfield',
						label: 'Имя на русском',
						placeholder: 'Ввести имя на кирилице',
						lang: 'ru'
					},
					{
						value: position_id,
						setter: setposition_id,
						optionListValue: positions,
						type: 'optionlist',
						usageType: 'optionlist',
						label: 'Հաստիք',
					},
				]}
				filesComponent={[
					{
						destiny: "image",
						file: file,
						filesUploadLimit: 1,
						acceptedFiles: ['image/*'],
						uploadFile: uploadFile,
						removeFile: removeFile,
						buttonAddText: 'Ավելացնել',
						buttonOpenText: 'Բացել',
						buttonRemoveText: 'Ջնջել',
						dropzoneText:
							'Բացակայում է ֆայլը, սեղմեք այստեղ՝ պատկեր ավելացնելու համար:',
					},
				]}
			/>
		);
}
