import React, { useState, useEffect } from "react";
// import './adminlte.css';
import "./scss/AdminLTE.scss";

// Helpers
import history from "./helpers/history";
import { cookies, setCookies } from "./helpers/cookies";
import { isLoggedIn } from "./helpers/auth";
import API from "./helpers/api";
import { useStateWithLocalStorage } from "./helpers/storage";
import config from "./config";
import Loading from "./components/modules/loading.jsx";

// Components
import Mission from "./components/about-us/mission/mission";
import History from "./components/about-us/history/history";
import Team from "./components/about-us/team/team";
import TeamList from "./components/about-us/team/team-list";
import License from "./components/about-us/license/license";

import Job from "./components/jobs/job";
import Jobs from "./components/jobs/jobs";
import Project from "./components/projects/project";
import Projects from "./components/projects/projects";

import Services from "./components/services/services";
import Service from "./components/services/service";

import NewsList from "./components/news/news-list";
import News from "./components/news/news";
import joinUs from "./components/join-us/joinUs";
import Clients from "./components/clients/clients";
import Client from "./components/clients/client";
import Partners from "./components/partners/partners";
import Partner from "./components/partners/partner";
import Opinions from "./components/opinion/opinions";
import Opinion from "./components/opinion/opinion";

import AnimatedNumbers from "./components/animated-numbers/animatedNumbers";

import Sliders from "./components/sliders/sliders";
import Slider from "./components/sliders/slider";
import Positions from "./components/positions/positions";
import Position from "./components/positions/position";
import Wchus from "./components/wchus/wchus";
import Media from "./components/media/media";

// global components
import Login from "./components/login";
import Home from "./components/home";
import Main from "./components/main/main";

//Routing
import { Router, Route, Switch } from "react-router-dom";
import ContactPage from "./components/contact/contactPage";
import ContactParts from "./components/contact/contactParts";
import ContactPart from "./components/contact/contactPart";
import ContactTypes from "./components/contact/contactTypes";
import ContactType from "./components/contact/contactType";

function RouterComponent() {
  return (
    <Route path="/" component={Home}>
      <Route path="/home" component={Home} />
      <Route exact path="/aboutus-mission" component={Mission} />
      {/* <Route exact path='/about-us/privilege' component={Privilege} /> */}
      <Route exact path="/aboutus-history" component={History} />
      <Route exact path="/aboutus-team" component={TeamList} />
      <Route exact path="/aboutus-team/:id" component={Team} />
      <Route exact path="/aboutus-license" component={License} />
      <Route exact path="/animated-numbers" component={AnimatedNumbers} />
      <Route exact path="/portfolio" component={Jobs} />
      <Route exact path="/portfolio/:id" component={Job} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/projects/:id" component={Project} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/services/:id" component={Service} />
      <Route exact path="/contact-page" component={ContactPage} />
      <Route exact path="/contact-part" component={ContactParts} />
      <Route exact path="/contact-part/:id" component={ContactPart} />
      <Route exact path="/contact-type" component={ContactTypes} />
      <Route exact path="/contact-type/:id" component={ContactType} />
      <Route exact path="/clients" component={Clients} />
      <Route exact path="/clients/:id" component={Client} />
      <Route exact path="/partners" component={Partners} />
      <Route exact path="/partners/:id" component={Partner} />
      <Route exact path="/opinion" component={Opinions} />
      <Route exact path="/opinion/:id" component={Opinion} />
      <Route exact path="/news" component={NewsList} />
      <Route exact path="/news/:id" component={News} />
      <Route exact path="/slider" component={Sliders} />
      <Route exact path="/slider/:id" component={Slider} />
      <Route exact path="/position" component={Positions} />
      <Route exact path="/position/:id" component={Position} />
      <Route exact path="/join-us" component={joinUs} />
      <Route exact path="/wchus" component={Wchus} />
      <Route exact path="/media" component={Media} />
    </Route>
  );
}
export default function App() {
  let [loggedIn, setLoggedIn] = useState(Boolean);
  // eslint-disable-next-line
  let [username, setUsername] = useStateWithLocalStorage("username");

  async function logout() {
    await setCookies("");
    await setUsername("");
    await setLoggedIn(false);
  }

  function login(username, password) {
    if (username && password) {
      return API.post(config.authentification.login, {
        username,
        password,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(res.data.token);
            setUsername(JSON.stringify(res.data.user.name));
            setLoggedIn(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      setLoggedIn(false);
      console.log("There is no user with provided username & password");
    }
  }

  async function checkToken() {
    let token = await cookies.get(config.authentification.tokenAddress);
    if (token) {
      API.post(config.authentification.verify, {
        token,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(token);
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }
        })
        .catch((res) => {
          console.error(res);
          console.error(`Please don't edit cookies manually`);
          cookies.remove("authorization");
        });
    } else {
      setLoggedIn(false);
      console.log(
        "You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running"
      );
    }
  }
  useEffect(() => {
    (async () => {
      await checkToken();
    })();
  }, [loggedIn]);

  if (loggedIn) {
    return (
      <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
        <Router history={history}>
          <Switch>
            <Main component={RouterComponent} />
          </Switch>
        </Router>
      </isLoggedIn.Provider>
    );
  } else {
    return (
      <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
        <Router history={history}>
          <Switch>
            <Route component={Login} />
          </Switch>
        </Router>
      </isLoggedIn.Provider>
    );
  }
}
