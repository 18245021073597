import React, { useState, useEffect } from 'react';
import SinglePage from '../../SinglePage';
import { useHistory } from 'react-router-dom';
import * as API from '../../../helpers/api';
import config from '../../../config';
import Loading from '../../modules/loding';

export default function Mission() {
	const history = useHistory();
	const [isBusy, setBusy] = useState(true);
	const [id, setid] = useState('');
	const pageTitle = 'Առաքելություն';

	const [descEn, setdescEn] = useState('');
	const [descRu, setdescRu] = useState('');
	const [descHy, setdescHy] = useState('');
	const [file, setfile] = useState(undefined);

	const modelSendToServer = {
		desc: {
			en: descEn,
			ru: descRu,
			hy: descHy,
		  }
	};

	//! components specific functions
	async function updateItem() {
		setBusy(true);
		await API.update(config.api.aboutUs.mission, id, modelSendToServer);
		history.push('/');
	}

	useEffect(() => {
		(async () => {
			let currentItem = await API.get(config.api.aboutUs.mission);

			setid(currentItem[0]?._id);
			setdescEn(currentItem[0]?.desc?.en);
			setdescRu(currentItem[0]?.desc?.ru);
			setdescHy(currentItem[0]?.desc?.hy);

			let files;
        if (id) {
          files = await API.get(config.api.uploads, {
            obj_id: id,
            destiny: "image",
          });
          setfile(files && files[0]);
        }

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={config.api.aboutUs.mission}
				pageTitle={pageTitle}
				UPDATE={updateItem}
				inputs={[
					{
						value: descHy,
						setter: setdescHy,
						type: 'text',
						usageType: 'textarea',
						label: 'Նկարագրություն',
						placeholder: 'Նկարագրություն',
						lang: 'hy'
					},
					{
						value: descRu,
						setter: setdescRu,
						type: 'text',
						usageType: 'textarea',
						label: 'Описание',
						placeholder: 'Описание',
						lang: 'ru'
					},
					{
						value: descEn,
						setter: setdescEn,
						type: 'text',
						usageType: 'textarea',
						label: 'Description',
						placeholder: 'Description',
						lang: 'en'
					},
				]}
			/>
		);
}
