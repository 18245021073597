import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useHistory } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loding";
import getUploads from "../../helpers/getUploads";


export default function JoinUs() {
  const history = useHistory();
  const [isBusy, setBusy] = useState(true);
  const [id, setid] = useState("");
  const pageTitle = "Միացեք մեզ";

  const [descEn, setdescEn] = useState("");
  const [descRu, setdescRu] = useState("");
  const [descHy, setdescHy] = useState("");
  const [file, setfile] = useState(undefined);

  const modelSendToServer = {
    desc: {
      en: descEn,
      ru: descRu,
      hy: descHy,
    }
  };

  //! components specific functions
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.joinus, id, modelSendToServer);
    history.goBack();
  }
  async function removeFile(id) {
    setBusy(true);
    setfile("");
    await API.remove(config.api.uploads, id);
  }
  async function uploadFile(files, lang, destiny) {
    if (id === "add") {
      const rowData = await API.post(config.api.joinus, modelSendToServer);
      let id = rowData._id;
      await history.push(`${id}`);
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    } else {
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    }
  }

  useEffect(() => {
    (async () => {
      let currentItem = await API.get(config.api.joinus);

        setid(currentItem[0]?._id);
        setdescEn(currentItem[0]?.desc?.en);
        setdescRu(currentItem[0]?.desc?.ru);
        setdescHy(currentItem[0]?.desc?.hy);
      let files;
      if (id) {
        files = await API.get(config.api.uploads, {
          obj_id: id,
          destiny: "pdf",
        });
        setfile(files && files[0]);
      }

      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.joinus}
        pageTitle={pageTitle}
        UPDATE={updateItem}
        inputs={[
          {
            value: descHy,
            setter: setdescHy,
            type: "text",
            usageType: "textarea",
            label: "Նկարագրություն",
            placeholder: "Նկարագրություն",
            lang: 'hy'
          },
          {
            value: descRu,
            setter: setdescRu,
            type: "text",
            usageType: "textarea",
            label: "Описание",
            placeholder: "Описание",
            lang: 'ru'
          },
          {
            value: descEn,
            setter: setdescEn,
            type: "text",
            usageType: "textarea",
            label: "Description",
            placeholder: "Description",
            lang: 'en'
          },
        ]}
        filesComponent={[
					{
						destiny: 'pdf',
						file: file,
						filesUploadLimit: 1,
						acceptedFiles: ['application/pdf'],
						uploadFile: uploadFile,
						removeFile: removeFile,
						buttonAddText: 'Ավելացնել',
						buttonOpenText: 'Բացել',
						buttonRemoveText: 'Ջնջել',
						dropzoneText:
							'Բացակայում է ֆայլը, սեղմեք այստեղ՝ պատկեր ավելացնելու համար:',
					},
        ]}
      />
    );
}
