import React, { useEffect, useState } from 'react';
import Sidebar from './sidebar';
import Navbar from './navbar';

import Loading from '../modules/loding';
import Footer from './footer';

export default function Main({ component: Component, ...rest }) {
	// eslint-disable-next-line
	let [username, setUsername] = useState('');
	let [isBusy, setIsBusy] = useState(true);
	const [open, setOpen] = React.useState(false);
	const setOpenSidebar = () => {
		setOpen(false);
		document.body.classList.remove('sidebar-close');
		document.body.classList.add('sidebar-open');
		document.body.classList.remove('sidebar-collapse');
	};
	const setCloseSidebar = () => {
		setOpen(true);
		document.body.classList.add('sidebar-collapse');
		document.body.classList.remove('sidebar-open');
		document.body.classList.add('sidebar-close');
	};

	const toggleSidebar = () => {
		if (open) setOpenSidebar();
		else setCloseSidebar();
	};

	useEffect(() => {
		(async () => {
			await setUsername(localStorage.getItem('username'));
			await setIsBusy(false);
			toggleSidebar();
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) {
		return <Loading />;
	} else {
		return (
			<>
				<Navbar settings={{ toggleSidebar }} />
				<Sidebar />
				<div className='content-wrapper'>
					<Component />
				</div>
				<div id='sidebar-overlay' onClick={toggleSidebar}></div>
				<Footer />
			</>
		);
	}
}
