import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loding';

export default function Project() {
  const history = useHistory();
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const pageTitle = 'Նախագիծ';

  const [index, setindex] = useState('');
  const [nameEn, setnameEn] = useState('');
  const [nameRu, setnameRu] = useState('');
  const [nameHy, setnameHy] = useState('');
  const [descEn, setdescEn] = useState('');
  const [descRu, setdescRu] = useState('');
  const [descHy, setdescHy] = useState('');
  const [image, setImage] = useState(undefined);
  // eslint-disable-next-line
  const [file, setfile] = useState(undefined);
  const [pdf, setpdf] = useState(undefined);

  const modelSendToServer = {
    index,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    desc: {
      en: descEn,
      ru: descRu,
      hy: descHy,
    },
  };

  //! components specific functions
  async function createItem() {
    setBusy(true);
    await API.post(config.api.projects, modelSendToServer);
    history.goBack();
  }
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.projects, id, modelSendToServer);
    history.goBack();
  }
  async function removeItem() {
    setBusy(true);
    await API.remove(config.api.projects, id);
    history.goBack();
  }
  async function removeFile(id) {
    setBusy(true);
    setfile('');
    await API.remove(config.api.uploads, id);
  }
  async function uploadFile(files, lang, destiny) {
    if (id === 'add') {
      const rowData = await API.post(config.api.projects, modelSendToServer);
      let id = rowData._id;
      await history.push(`${id}`);
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    } else {
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    }
  }

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        let currentItem = await API.get(config.api.projects, {
          _id: id,
        });
        setindex(currentItem[0]?.index);
        setnameEn(currentItem[0]?.name?.en);
        setnameRu(currentItem[0]?.name?.ru);
        setnameHy(currentItem[0]?.name?.hy);
        setdescEn(currentItem[0]?.desc?.en);
        setdescRu(currentItem[0]?.desc?.ru);
        setdescHy(currentItem[0]?.desc?.hy);

        let files;
        let pdfs;
        if (id) {
          files = await API.get(config.api.uploads, {
            obj_id: id,
            destiny: 'image',
          });
          setfile(files && files[0]);
          pdfs = await API.get(config.api.uploads, {
            obj_id: id,
            destiny: 'pdf',
          });
          setpdf(pdfs && pdfs[0]);
        }
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.projects}
        pageTitle={pageTitle}
        UPDATE={updateItem}
        CREATE={createItem}
        REMOVE={removeItem}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: 'number',
            usageType: 'number',
            label: 'Index',
            placeholder: 'Index',
          },
          {
            value: nameRu,
            setter: setnameRu,
            type: 'text',
            usageType: 'textfield',
            label: 'Имя на русском',
            placeholder: 'Ввести имя на кирилице',
            lang: 'ru'
          },
          {
            value: nameEn,
            setter: setnameEn,
            type: 'text',
            usageType: 'textfield',
            label: 'Name in english',
            placeholder: 'Input name in latin',
            lang: 'en'
          },
          {
            value: descHy,
            setter: setdescHy,
            type: 'text',
            usageType: 'textarea',
            label: 'Նկարագրություն',
            placeholder: 'Մուտքագրել անունը հայատառ',
            lang: 'hy'
          },
          {
            value: descRu,
            setter: setdescRu,
            type: 'text',
            usageType: 'textarea',
            label: 'Описание',
            placeholder: 'Ввести имя на кирилице',
            lang: 'ru'
          },
          {
            value: descEn,
            setter: setdescEn,
            type: 'text',
            usageType: 'textarea',
            label: 'Description',
            placeholder: 'Input name in latin',
            lang: 'en'
          },
        ]}
        filesComponent={[
          {
            destiny: 'image',
            file: file,
            filesUploadLimit: 1,
            acceptedFiles: ['image/*'],
            uploadFile: uploadFile,
            removeFile: removeFile,
            buttonAddText: 'Ավելացնել',
            buttonOpenText: 'Բացել',
            buttonRemoveText: 'Ջնջել',
            dropzoneText:
              'Բացակայում է ֆայլը, սեղմեք այստեղ՝ պատկեր ավելացնելու համար:',
          },
          {
            destiny: 'pdf',
            file: pdf,
            filesUploadLimit: 1,
            acceptedFiles: ['application/pdf'],
            uploadFile: uploadFile,
            removeFile: removeFile,
            buttonAddText: 'Ավելացնել',
            buttonOpenText: 'Բացել',
            buttonRemoveText: 'Ջնջել',
            dropzoneText:
              'Բացակայում է ֆայլը, սեղմեք այստեղ՝ պատկեր ավելացնելու համար:',
          },
        ]}
      />
    );
}
