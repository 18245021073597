import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loding';

export default function Opinion() {
	const history = useHistory();
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const pageTitle = 'Կարծիք';

	const [firstNameEn, setfirstNameEn] = useState('');
	const [firstNameRu, setfirstNameRu] = useState('');
	const [firstNameHy, setfirstNameHy] = useState('');
	const [lastNameEn, setlastNameEn] = useState('');
	const [lastNameRu, setlastNameRu] = useState('');
	const [lastNameHy, setlastNameHy] = useState('');
	const [descEn, setdescEn] = useState('');
	const [descRu, setdescRu] = useState('');
	const [descHy, setdescHy] = useState('');
	const [company, setcompany] = useState('');
	const [position, setposition] = useState('');
	const [image, setImage] = useState(undefined);
	// eslint-disable-next-line
	const [file, setfile] = useState(undefined);

	const modelSendToServer = {
		firstName: {
			en: firstNameEn,
			ru: firstNameRu,
			hy: firstNameHy,
			},
			lastName: {
				en: lastNameEn,
				ru: lastNameRu,
				hy: lastNameHy,
				},
			desc: {
				en: descEn,
				ru: descRu,
				hy: descHy,
				},
			company: company,
			position: position
	};

	//! components specific functions
	async function createItem() {
		setBusy(true);
		await API.post(config.api.opinion, modelSendToServer);
		history.goBack();
	}
	async function updateItem() {
		setBusy(true);
		await API.update(config.api.opinion, id, modelSendToServer);
		history.goBack();
	}
	async function removeItem() {
		setBusy(true);
		await API.remove(config.api.opinion, id);
		history.goBack();
	}
	async function removeFile(id) {
		setBusy(true);
		setfile("");
		await API.remove(config.api.uploads, id);
	  }
	  async function uploadFile(files, lang, destiny) {
		if (id === "add") {
		  const rowData = await API.post(config.api.opinion, modelSendToServer);
		  let id = rowData._id;
		  await history.push(`${id}`);
		  let result = await API.upload(id, files, lang, destiny);
		  if (result) setBusy(true);
		} else {
		  let result = await API.upload(id, files, lang, destiny);
		  if (result) setBusy(true);
		}
	}

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				let currentItem = await API.get(config.api.opinion, {
					_id: id,
				});
				setfirstNameEn(currentItem[0]?.firstName?.en);
				setfirstNameRu(currentItem[0]?.firstName?.ru);
				setfirstNameHy(currentItem[0]?.firstName?.hy);
				setlastNameEn(currentItem[0]?.lastName?.en);
				setlastNameRu(currentItem[0]?.lastName?.ru);
				setlastNameHy(currentItem[0]?.lastName?.hy);
				setdescEn(currentItem[0]?.desc?.en);
				setdescRu(currentItem[0]?.desc?.ru);
				setdescHy(currentItem[0]?.desc?.hy);
				setcompany(currentItem[0]?.company);
				setposition(currentItem[0]?.position);

				let files;
				if (id) {
					files = await API.get(config.api.uploads, {
						obj_id: id,
						destiny: "image",
					});
					setfile(files && files[0]);
        		}
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={config.api.opinion}
				pageTitle={pageTitle}
				UPDATE={updateItem}
				CREATE={createItem}
				REMOVE={removeItem}
				inputs={[
					{
						value: firstNameHy,
						setter: setfirstNameHy,
						type: 'text',
						usageType: 'textfield',
						label: 'Անունը հայերեն',
						placeholder: 'Մուտքագրել անունը հայատառ',
						lang: 'hy'
					},
					{
						value: firstNameRu,
						setter: setfirstNameRu,
						type: 'text',
						usageType: 'textfield',
						label: 'Имя на русском',
						placeholder: 'Ввести имя на кирилице',
						lang: 'ru'
					},
					{
						value: firstNameEn,
						setter: setfirstNameEn,
						type: 'text',
						usageType: 'textfield',
						label: 'Name in english',
						placeholder: 'Input name in latin',
						lang: 'en'
					},
					{
						value: lastNameHy,
						setter: setlastNameHy,
						type: 'text',
						usageType: 'textfield',
						label: 'Ազգանունը հայերեն',
						placeholder: 'Մուտքագրել անունը հայատառ',
						lang: 'hy'
					},
					{
						value: lastNameRu,
						setter: setlastNameRu,
						type: 'text',
						usageType: 'textfield',
						label: 'Фамилия на русском',
						placeholder: 'Ввести имя на кирилице',
						lang: 'ru'
					},
					{
						value: lastNameEn,
						setter: setlastNameEn,
						type: 'text',
						usageType: 'textfield',
						label: 'Lastname in english',
						placeholder: 'Input name in latin',
						lang: 'en'
					},
					{
						value: company,
						setter: setcompany,
						type: 'text',
						usageType: 'textfield',
						label: 'Company',
						placeholder: 'Input name in latin',
					},
					{
						value: position,
						setter: setposition,
						type: 'text',
						usageType: 'textfield',
						label: 'Position',
						placeholder: 'Input name in latin',
					},
					{
						value: descHy,
						setter: setdescHy,
						type: 'text',
						usageType: 'textarea',
						label: 'Նկարագրություն',
						placeholder: 'Մուտքագրել անունը հայատառ',
						lang: 'hy'
					},
					{
						value: descRu,
						setter: setdescRu,
						type: 'text',
						usageType: 'textarea',
						label: 'Описание',
						placeholder: 'Ввести имя на кирилице',
						lang: 'ru'
					},
					{
						value: descEn,
						setter: setdescEn,
						type: 'text',
						usageType: 'textarea',
						label: 'Description',
						placeholder: 'Input name in latin',
						lang: 'en'
					},
				]}
				filesComponent={[
					{
						destiny: "image",
						file: file,
						filesUploadLimit: 1,
						acceptedFiles: ['image/*'],
						uploadFile: uploadFile,
						removeFile: removeFile,
						buttonAddText: 'Ավելացնել',
						buttonOpenText: 'Բացել',
						buttonRemoveText: 'Ջնջել',
						dropzoneText:
							'Բացակայում է ֆայլը, սեղմեք այստեղ՝ պատկեր ավելացնելու համար:',
					},
				]}
			/>
		);
}
