import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useHistory } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loding";

export default function AnimatedNumbers() {
  const history = useHistory();
  const [isBusy, setBusy] = useState(true);
  const [id, setid] = useState("");
  const pageTitle = "Հիմնական էջի թվեր";

  const [counter1, setCounter1] = useState("");
  const [counter2, setCounter2] = useState("");
  const [counter3, setCounter3] = useState("");

  const modelSendToServer = {
    counter1,
    counter2,
    counter3,
  };

  //! components specific functions
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.animatedNumbers, id, modelSendToServer);
    history.goBack();
  }

  useEffect(() => {
    (async () => {
      let currentItem = await API.get(config.api.animatedNumbers);
      setid(currentItem[0]?._id);
      setCounter1(currentItem[0]?.counter1);
      setCounter2(currentItem[0]?.counter2);
      setCounter3(currentItem[0]?.counter3);

      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.animatedNumbers}
        pageTitle={pageTitle}
        UPDATE={updateItem}
        inputs={[
          {
            value: counter1,
            setter: setCounter1,
            type: "number",
            usageType: "number",
            label: "Ավարտված",
            placeholder: "Ավարտված",
          },
          {
            value: counter2,
            setter: setCounter2,
            type: "number",
            usageType: "number",
            label: "Ընթացիկ",
            placeholder: "Ընթացիկ",
          },
          {
            value: counter3,
            setter: setCounter3,
            type: "number",
            usageType: "number",
            label: "Մեր թիմը",
            placeholder: "Մեր թիմը",
          },
        ]}
      />
    );
}
