import React from "react";

export default function Footer(props) {
  return (
    <footer className="main-footer">
      <strong>
        Designed and developed by{" "}
        <a href="https://deepmindsystems.com">Deepmindsystems</a>
        {/* <img
          src="/Logo.svg"
          alt="deepmindsystems"
          className="deepmindsystems-logo"
        /> */}
      </strong>
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 1.0.0
      </div>
    </footer>
  );
}
