import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import { useHistory } from "react-router-dom";
import Loading from "../modules/loading";

export default function ContactParts() {
  const [apiData, setApiData] = useState({});
  const [isBusy, setBusy] = useState(true);
  let history = useHistory();
  const pageTitle = "Էլեմենտներ";
  const addNew = () => {
    history.push(`add`);
  };

  useEffect(() => {
    (async () => {
      let rawData = await API.get(config.api.contactPart);
      setApiData(rawData);
      setBusy(false);
    })();
  }, [isBusy]);

  const columns = [
    {
      dataField: "name.en",
      text: "Name",
      filter: true,
      sort: true,
    },
    {
      dataField: "name.ru",
      text: "Заголовок",
      filter: true,
      sort: true,
    },
    {
      dataField: "name.hy",
      text: "Վերնագիր",
      filter: true,
      sort: true,
    },
    {
      dataField: "icon",
      text: "Icon",
      formatIcon: true,
    },
    {
      dataField: "contact_type",
      text: "Contact Type",
      filter: true,
      sort: true,
      formatContactTypes: true,
    },
  ];

  if (isBusy) return <Loading />;
  else
    return (
      <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12" align="center">
                <h1>{`${pageTitle} List`}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <ListPage
                      apiData={apiData}
                      columns={columns}
                      route={config.api.contactPart}
                      addNew={addNew}
                      pageTitle={pageTitle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}
