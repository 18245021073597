import React, { useState, Fragment } from "react";
import config from "../../config";
import Dropzone from "react-dropzone";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export function SliderComponent(props) {
  const [openDialog, setDialog] = useState(false);

  var disabled = false;
  if (props.files && props.files[0] !== undefined) {
    disabled = true;
  }

  const handleClose = () => {
    setDialog(false);
  };

  const handleSave = (files) => {
    setDialog(false);
    props.uploadFile(files, props.lang);
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const removeFile = (e) => {
    props.removeFile(e.currentTarget.id);
  };

  return (
    <Fragment>
      <div className="col-md-12">
        <div className="card card-secondary">
          <div className="card-header">
            <h3 className="card-title">Ֆայլ</h3>
          </div>
          <div className="card-body">
            {disabled ? (
              <Carousel showArrows={true}>
                {props.files.map((file, index) => {
                  return (
                    <div key={index}>
                      <button
                        onClick={removeFile}
                        type="button"
                        id={file._id}
                        className="btn btn-danger slider-remove-button"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                      <img src={`${config.api.API_URL}/${file.path}`} alt="" />
                    </div>
                  );
                })}
              </Carousel>
            ) : (
              <Dropzone onDrop={(acceptedFiles) => handleSave(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>{props.dropzoneText}</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </div>
          <div className="card-footer">
            <button
              onClick={handleOpen}
              type="button"
              className="btn btn-primary"
            >
              <i className="fas fa-upload"></i>
              <span style={{ marginLeft: 10 }}>{props.buttonAddText}</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className={openDialog ? "modal fade show" : "modal fade"}
        id="modal-xl"
        style={{
          display: openDialog ? "block" : "none",
          paddingRight: 16,
        }}
        aria-modal="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Ավելացնել ֆայլեր</h4>
              <button
                type="button"
                onClick={handleClose}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <Dropzone onDrop={(acceptedFiles) => handleSave(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>{props.dropzoneText}</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-default"
                data-dismiss="modal"
              >
                <i className="fas fa-window-close"></i>
                <span style={{ marginLeft: 10 }}>{props.buttonCloseText}</span>
              </button>
              <button
                type="button"
                onClick={handleSave}
                className="btn btn-primary"
              >
                <i className="fas fa-save"></i>
                <span style={{ marginLeft: 10 }}>{props.buttonSaveText}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
