import React from "react";
import config from "../../config";
import Dropzone from "react-dropzone";

export function FilesComponent(props) {
  var disabled = false;
  var fileId = "";

  if (props.file === undefined) {
    disabled = true;
  } else if (props.file) {
    fileId = props.file._id;
  }

  const handleSave = (files) => {
    props.uploadFile(files, props.lang, props.destiny);
  };

  const openInNewTab = (e) => {
    let win = window.open(`${config.api.API_URL}/${props.file.path}`, "_blank");
    win.focus();
  };

  const removeFile = (e) => {
    props.removeFile(e.currentTarget.id);
  };

  return (
    <>
      <div className="col-md-12">
        <div className="card card-secondary">
          <div className="card-header">
            <h3 className="card-title">{props.destiny ? props.destiny.charAt(0).toUpperCase() + props.destiny.slice(1) : "Files"} section</h3>
          </div>
          <div className="card-body">
            {!disabled ? (
              props.file.type === "application/pdf" ? (
                <embed
                  type="application/pdf"
                  width="100%"
                  src={`${config.api.API_URL}/${props.file.path}`}
                />
              ) : (
                <img src={`${config.api.API_URL}/${props.file.path}`} alt="" />
              )
            ) : (
              <Dropzone onDrop={(acceptedFiles) => handleSave(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>{props.dropzoneText}</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
          </div>
          <div className="card-footer">
            <div className="btn-group">
              <button
                onClick={openInNewTab}
                disabled={props.slider || disabled}
                display={props.slider ? "none" : "inline"}
                type="button"
                className="btn btn-default"
              >
                <i className="fas fa-share"></i>
                <span style={{ marginLeft: 10 }}>{props.buttonOpenText}</span>
              </button>
              <button
                onClick={removeFile}
                disabled={disabled}
                type="button"
                id={fileId}
                className="btn btn-danger"
              >
                <i className="fas fa-trash"></i>
                <span style={{ marginLeft: 10 }}>{props.buttonRemoveText}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
