import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import Dropzone from "react-dropzone";
import Pagination from "../../helpers/paginate";
// import { useLang } from "../../helpers/language";

const PaginationContent = ({
  paginationContent,
  loading,
  // lang,
  setOpen,
  removeFile,
  openInNewTab,
}) => {
  const copyToPlipboard = async (e) => {
    let url = `${config.api.API_URL}/${e.currentTarget.id}`;
    await navigator.clipboard.writeText(url);
    toggleAlert();
  };

  const toggleAlert = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  if (loading) return <Loading />;
  return paginationContent.map((pgnContent, index) => (
    <div
      key={index}
      className="card card-secondary col-xs-12 col-sm-6 col-md-4 col-lg-3 media-card"
    >
      <div className="card-body">
        <img src={`${config.api.API_URL}/${pgnContent.path}`} alt="" />
      </div>
      <div className="card-footer">
        <div className="btn-group">
          <button
            className="btn btn-info"
            id={pgnContent.path}
            onClick={copyToPlipboard}
          >
            <i className="fas fa-link"></i>
          </button>
          <button
            className="btn btn-default"
            id={pgnContent.path}
            onClick={openInNewTab}
          >
            <i className="fas fa-share"></i>
          </button>
          <button
            className="btn btn-danger"
            id={pgnContent._id}
            onClick={removeFile}
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  ));
};

export default function Media() {
  let history = useHistory();
  const [isBusy, setBusy] = useState(true);
  const [files, setFiles] = useState("");
  let [screenHeight] = useState(window.innerHeight.toString());
  // let { lang } = useLang();
  const [open, setOpen] = React.useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(screenHeight > 1050 ? 8 : 4);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // Get current posts
  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPost = indexOfLastPost - dataPerPage;
  const currentData = files.slice(indexOfFirstPost, indexOfLastPost);

  const handleSave = (files, lang) => {
    uploadFile(files, lang);
  };

  //! components specific functions
  async function removeFile(e) {
    setBusy(true);
    await API.remove(config.api.uploads, e.currentTarget.id);
  }

  async function uploadFile(files, lang, destiny, id) {
    setBusy(true);
    await API.upload(id ? id : "", files, lang);
    setBusy(true);
  }

  const openInNewTab = (e) => {
    let url = `${config.api.API_URL}/${e.currentTarget.id}`;
    let win = window.open(url, "_blank");
    win.focus();
  };

  useEffect(() => {
    (async () => {
      const rowData = await API.get(
        config.api.uploads,
        {},
        {
          dateAdded: -1,
        }
      );
      setFiles(rowData);
      setTimeout(() => {
        setBusy(false);
      }, 1000);
    })();
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12" align="center">
                <h1>Մեդիա</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className={open ? "alert show" : "alert"}>
              <div className="toast bg-info fade show">
                <div className="toast-body">Լինկը պատճենվել է</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <Dropzone
                      onDrop={(acceptedFiles) => handleSave(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              {"There is no file, click to upload a new one"}
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <div className="card-body">
                    <div
                      className="row"
                      style={{
                        justifyContent: "space-around",
                      }}
                    >
                      {files !== undefined ? (
                        <>
                          <PaginationContent
                            paginationContent={currentData}
                            loading={isBusy}
                            // lang={lang}
                            removeFile={removeFile}
                            setOpen={setOpen}
                            openInNewTab={openInNewTab}
                          />
                          <Pagination
                            dataPerPage={dataPerPage}
                            totalCount={files.length}
                            paginate={paginate}
                            currentPage={currentPage}
                          />
                        </>
                      ) : (
                        <h2 className="text-center w-100 align-self-center">
                          Here are no content to show
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}
