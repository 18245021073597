import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams, useHistory } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import getUploads from "../../helpers/getUploads";

export default function ContactPart() {
  const history = useHistory();
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const pageTitle = "Contact Part";
  const [image, setImage] = useState(undefined);

  //! component specific properties
  const [nameEn, setnameEn] = useState("");
  const [nameRu, setnameRu] = useState("");
  const [nameHy, setnameHy] = useState("");
  const [contactTypes_id, setcontactTypes_id] = useState(undefined);
  const [contactTypes, setcontactTypes] = useState(undefined);
  const [value, setValue] = useState(undefined);

  const modelSendToServer = {
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    value,
    contact_type_id: contactTypes_id ? contactTypes_id : null,
  };

  //! components specific functions
  async function createItem() {
    setBusy(true);
    await API.post(config.api.contactPart, modelSendToServer);
    history.goBack();
  }
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.contactPart, id, modelSendToServer);
    history.goBack();
  }
  async function removeItem() {
    setBusy(true);
    await API.remove(config.api.contactPart, id);
    history.goBack();
  }

  async function removeFile(id) {
    setBusy(true);
    setImage("");
    await API.remove(config.api.uploads, id);
  }
  async function uploadFile(files, lang, destiny) {
    if (id === "add") {
      const rowData = await API.post(config.api.contactPart, modelSendToServer);
      id = rowData._id;
      await history.push(`${id}`);
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    } else {
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    }
  }

  useEffect(() => {
    (async () => {
      if (id !== "add") {
        let currentItem = await API.get(config.api.contactPart, {
          _id: id,
        });
        setnameEn(currentItem[0]?.name?.en);
        setnameRu(currentItem[0]?.name?.ru);
        setnameHy(currentItem[0]?.name?.hy);
        setValue(currentItem[0]?.value);
        setcontactTypes_id(currentItem[0]?.contact_type_id);

        let images = await getUploads(id, "icon");
        setImage(images && images[0]);
      }

      let rawData = await API.get(config.api.contactType);
      const contactTypes = rawData;
      setcontactTypes(contactTypes);

      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.contactPart}
        pageTitle={pageTitle}
        UPDATE={updateItem}
        CREATE={createItem}
        REMOVE={removeItem}
        inputs={[
          {
            value: nameEn,
            setter: setnameEn,
            type: "text",
            usageType: "textfield",
            label: "Name in english",
            placeholder: "Input in latin",
            lang: 'en'
          },

          {
            value: nameRu,
            setter: setnameRu,
            type: "text",
            usageType: "textfield",
            label: "Заголовок на русском",
            placeholder: "Ввести заголовок на кирилице",
            lang: 'ru'
          },
          {
            value: nameHy,
            setter: setnameHy,
            type: "text",
            usageType: "textfield",
            label: "Վերնագիր հայերեն",
            placeholder: "Մուտքագրել վերնագիր հայատառ",
            lang: 'hy'
          },
          {
            value: value,
            setter: setValue,
            type: "text",
            usageType: "textfield",
            label: "Արժեքը",
            placeholder: "Մուտքագրել վերնագիր հայատառ",
          },
          {
            value: contactTypes_id,
            field: "titleHy",
            setter: setcontactTypes_id,
            optionListValue: contactTypes,
            type: "optionlist",
            usageType: "optionlist",
            label: "Service types",
          },
        ]}
        filesComponent={[
          {
            destiny: "icon",
            file: image,
            filesUploadLimit: 1,
            acceptedFiles: ["image/*"],
            uploadFile: uploadFile,
            removeFile: removeFile,
            buttonAddText: "Add",
            buttonOpenText: "Open",
            buttonRemoveText: "Remove",
            dropzoneText: "There is no file, click to upload a new one",
          },
        ]}
      />
    );
}
