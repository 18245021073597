import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams, useHistory } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import getUploads from "../../helpers/getUploads";

export default function ContactType() {
  const history = useHistory();
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const pageTitle = "Contact Type";
  const [image, setImage] = useState(undefined);

  //! component specific properties
  const [nameEn, setnameEn] = useState("");
  const [nameRu, setnameRu] = useState("");
  const [nameHy, setnameHy] = useState("");
  const [href, sethref] = useState("");

  const modelSendToServer = {
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    href,
  };

  //! components specific functions
  async function createItem() {
    setBusy(true);
    await API.post(config.api.contactType, modelSendToServer);
    history.goBack();
  }
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.contactType, id, modelSendToServer);
    history.goBack();
  }
  async function removeItem() {
    setBusy(true);
    await API.remove(config.api.contactType, id);
    history.goBack();
  }

  async function removeFile(id) {
    setBusy(true);
    setImage("");
    await API.remove(config.api.uploads, id);
  }
  async function uploadFile(files, lang, destiny) {
    if (id === "add") {
      const rowData = await API.post(config.api.contactType, modelSendToServer);
      id = rowData._id;
      await history.push(`${id}`);
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    } else {
      let result = await API.upload(id, files, lang, destiny);
      if (result) setBusy(true);
    }
  }

  useEffect(() => {
    (async () => {
      if (id !== "add") {
        let currentItem = await API.get(config.api.contactType, {
          _id: id,
        });
        setnameEn(currentItem[0]?.name?.en);
        setnameRu(currentItem[0]?.name?.ru);
        setnameHy(currentItem[0]?.name?.hy);

        sethref(currentItem[0].href);
        let images = await getUploads(id, "image");
        setImage(images && images[0]);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.sliders}
        pageTitle={pageTitle}
        UPDATE={updateItem}
        CREATE={createItem}
        REMOVE={removeItem}
        inputs={[
          {
            value: nameEn,
            setter: setnameEn,
            type: "text",
            usageType: "textfield",
            label: "Name in english",
            placeholder: "Input in latin",
            lang: 'en'
          },

          {
            value: nameRu,
            setter: setnameRu,
            type: "text",
            usageType: "textfield",
            label: "Заголовок на русском",
            placeholder: "Ввести на кирилице",
            lang: 'ru'
          },
          {
            value: nameHy,
            setter: setnameHy,
            type: "text",
            usageType: "textfield",
            label: "Վերնագիր հայերեն",
            placeholder: "Մուտքագրել հայատառ",
            lang: 'hy'
          },
          {
            value: href,
            setter: sethref,
            type: "text",
            usageType: "textfield",
            label: "Reference",
            placeholder: "EnInput in latin",
          },
        ]}
        // filesComponent={[
        //   {
        //     destiny: "image",

        //     file: image,
        //     filesUploadLimit: 1,
        //     acceptedFiles: ["image/*"],
        //     uploadFile: uploadFile,
        //     removeFile: removeFile,
        //     buttonAddText: "Add",
        //     buttonOpenText: "Open",
        //     buttonRemoveText: "Remove",
        //     dropzoneText: "There is no file, click to upload a new one",
        //   },
        // ]}
      />
    );
}
