import * as API from "./api";
import config from "../config";

export default async function getUploads(id, destiny, type) {
  let uploads, query;
  if (id && destiny && type) {
    query = {
      obj_id: id,
      destiny,
      type,
    };
  } else if (id && destiny) {
    query = {
      obj_id: id,
      destiny,
    };
  } else if (id && type) {
    query = {
      obj_id: id,
      type,
    };
  } else if (destiny && type) {
    query = {
      destiny,
      type,
    };
  } else if (id) {
    query = {
      obj_id: id,
    };
  } else if (type) {
    query = {
      type,
    };
  } else if (destiny) {
    query = {
      destiny,
    };
  }
  try {
    uploads = await API.get(config.api.uploads, query);
    return uploads;
  } catch (err) {
    console.log(err);
  }
}
